import './SignIn.css';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import userPhone from '../../assets/img/phone.svg';
import logo from '../../assets/img/logo.png';
import { DivBox, Button, Input } from '../../components/index';
import { postData } from '../../Service/ServiceAPI';

const SignIn = ({ setIsAuthenticated }) => {
  const [userphone, setUserphone] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const postUserData = async () => {
    try {
      const dataToSend = { phone_number: userphone };
      const result = await postData('/customers/login', dataToSend);

      if (result && result.message === 'تم تسجيل دخولك') {
        setErrorMessage('');
        setIsAuthenticated(true); 
        navigate('/Rate');
      } else {
        setErrorMessage('Unexpected login response.');
      }
      
    } catch (error) {
      setErrorMessage(error.response?.data?.message || ''); // Display error message
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postUserData();
  };

  return (
    <div className='SignIn-page'>
      <div className="container d-flex justify-content-center align-items-center min-vh-100">
        <img alt='' src={logo} className="pb-5 pt-1" />
        <form onSubmit={handleSubmit}>
          <DivBox>
            <p className="box-title">تسجيل الدخول</p>
            <p className="box-text">ادخل رقم هاتفك للتأكد من حسابك</p>
            <div className="box-input">
              <img alt='' src={userPhone} className="box-input-icon" />
              <Input
                type='text'
                placeholder='رقم هاتفك'
                value={userphone}
                onChange={(e) => setUserphone(e.target.value)}
              />
              <div className='error' id="error">
                {errorMessage}
              </div>
            </div>
            <p className="box-link pb-5">لا نمتلك حساب ؟
              <Link to="/SignUp" className="box-link" >
              أنشاء حساب
              </Link>
            </p>
            <Button type="submit">تسجيل الدخول</Button>
          </DivBox>
        </form>
      </div>
    </div>
  );
}

export default SignIn;
