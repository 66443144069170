import './LatestVisit.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postData, getData } from '../../Service/ServiceAPI';
import shadowOreng from '../../assets/img/shadowOreng.svg';
import shadowRed from '../../assets/img/shadowRed.svg';
import arrowRed from '../../assets/img/arrowRed.svg';
import arrowBlack from '../../assets/img/arrowBlack.svg';
import warning from '../../assets/img/warning.svg';
import edit from '../../assets/img/edit.svg';

const LatestVisit = () => {
    const [cardsData, setCardsData] = useState([]);
    const [expanded, setExpanded] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [cardsPerPage] = useState(2);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const handleButtonClick = () => {
        navigate('/ResturantInfo');
    };

    const fetchData = async (page = 1) => {
        try {
            const response = await getData(`/feedback?page=${page}`);
            const feedbackData = response.data.data;
            setCardsData((prevData) => [...prevData, ...feedbackData]); // Append new data to existing data
            setTotalPages(response.data.last_page); // Set total pages from response
        } catch (error) {
            console.error('Error fetching the data:', error);
        }
    };


    useEffect(() => {
        fetchData(); // Fetch the first page of data
    }, []);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const CardComponent = ({ data, onUpdate }) => {
        const [generalExperience, setGeneralExperience] = useState(data.general_experience);
        const [mostLikedMeal, setMostLikedMeal] = useState(data.most_liked_meal);
        const [mealRatings, setMealRatings] = useState(data.meals.map(meal => meal.rating));

        const handleMealRatingChange = (index, rating) => {
            const updatedRatings = [...mealRatings];
            updatedRatings[index] = rating;
            setMealRatings(updatedRatings);
        };
        const handleSave = async () => {
            await postUserData(data.id);
        };

        const postUserData = async (feedbackId) => { // Use feedbackId for clarity
            try {
                const dataToSend = {
                    id: feedbackId,
                    most_liked_meal: mostLikedMeal,
                    general_experience: generalExperience,
                    meals: data.meals.map((meal, index) => ({
                        meal_id: meal.id, // Keep the meal ID for the request
                        rating: mealRatings[index],
                    })),
                };
                // Send the feedback ID in the URL
                const result = await postData(`/feedback/update?id=${feedbackId}`, dataToSend);
                console.log(result); // Optional: Handle the response or errors
            } catch (error) {
                alert("لا يمكن تحديث التغذية الراجعة بعد مرور ساعة من إنشائها");
            }
        };
        return (
            <div className='card mb-5'>
                <div className='w-100 d-flex justify-content-end'>
                    <div className='card-title'>
                        <div className='card-visit-number'>
                            <div className='card-visit-date'>
                                <p style={{ direction: 'rtl' }}>
                                    {new Date(data.created_at).toLocaleDateString({
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric'
                                    })}
                                </p>
                            </div>
                            <div className='card-visit-title'>
                                <p>زياره</p>
                                <p>{data.visit}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row w-100 card-info-title'>
                    <div className='col-12 col-lg-4 order-2 pt-3 order-lg-0'>
                        : تجربتك بشكل عام
                        <div className='col w-100 d-flex align-items-start ' style={{paddingLeft:'15px'}}>
                            <img src={edit} alt="" />
                            <textarea
                                type="text"
                                className='mostLiked overflow-auto'
                                style={{ height: 'fit-content', width: '100%', textAlign: '' }}
                                value={generalExperience}
                                onChange={(e) => setGeneralExperience(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='col-12 col-lg-5 order-1 py-3 order-lg-1 line-under'>
                        : تقييم الوجبات
                        <ul className="custom-list openion">
                            {data.meals.map((meal, index) => (
                                <li key={index}>
                                    <p>{meal.meal_name}</p>
                                    <select
                                        style={{
                                            backgroundColor: mealRatings[index] === "10" ? '#4CAF4F' :
                                                mealRatings[index] === "9" ? '#78C67A' :
                                                    mealRatings[index] === "8" ? '#C0E0B1' :
                                                        mealRatings[index] === "7" ? '#ABCA6A' :
                                                            mealRatings[index] === "6" ? '#C1CB4C' :
                                                                mealRatings[index] === "5" ? '#D4C119' :
                                                                    mealRatings[index] === "4" ? '#D49519' :
                                                                        mealRatings[index] === "3" ? '#D46819' :
                                                                            mealRatings[index] === "2" ? '#D43B19' :
                                                                                mealRatings[index] === "1" ? '#992222' : '',
                                            border: 'none',
                                            minWidth: '70px',
                                            borderRadius: '4px',
                                            color: mealRatings[index] >= "5" && mealRatings[index] <= "8" ? '#000000' : 'white'
                                        }}
                                        value={mealRatings[index]}
                                        onChange={(e) => handleMealRatingChange(index, e.target.value)}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(rating => (
                                            <option key={rating} value={rating}>{rating}/10</option>
                                        ))}
                                    </select>

                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='col-12 col-lg-3 order-0 py-3 order-lg-2 line-under' style={{paddingLeft:'20px'}}>
                        : أكثر وجبة أعجبتك
                        <div className='col w-100 d-flex'>
                            <img src={edit} alt="" />
                            <input
                                type="text"
                                className='mostLiked'
                                value={mostLikedMeal}
                                onChange={(e) => setMostLikedMeal(e.target.value)}
                            />
                        </div>
                    </div>
                    <button onClick={handleSave} className='makeTheChange order-3 order-lg-3'>Save</button>
                </div>
            </div>
        );
    };

    const handleCardUpdate = (updatedData, index) => {
        const newCardsData = [...cardsData];
        newCardsData[index] = updatedData;
        setCardsData(newCardsData);
    };

    const loadMoreCards = async () => {
        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            await fetchData(nextPage); // Fetch the next page of data
            setCurrentPage(nextPage); // Update the current page
        }
    };

    // Get current cards based on pagination
    const currentCards = cardsData.slice(0, currentPage * cardsPerPage);

    return (
        <>
            <img src={shadowRed} alt="" className='shadow-1' />
            <img src={shadowRed} alt="" className='shadow-2' />
            <img src={shadowOreng} alt="" className='shadow-3' />
            <img src={shadowRed} alt="" className='shadow-4' />
            <div className={`container d-flex justify-content-center align-items-center pb-5 ${expanded ? 'expanded' : ''}`}>
                <div className={`boxVisit ${expanded ? 'expanded' : ''}`}>
                    <div className="boxVisit-cont">
                        <div className='row w-100 justify-content-center' style={{ flexDirection: 'row-reverse', height: '100%' }}>
                            <div className='col px-0 d-flex justify-content-end'>
                                <button className='back-btn' onClick={handleButtonClick}>
                                    <p className='back-text'>رجوع</p>
                                    <img src={arrowBlack} alt="" />
                                </button>
                            </div>
                            <div className='col-11 pt-0 pt-sm-5 d-flex justify-content-center align-items-center gap-2'>
                                <p className='warning-text'>تنتهي مدة صلاحية التعديل خلال ساعة من وقت التقييم</p>
                                <img src={warning} alt="" />
                            </div>
                            <div className='col-lg-10 col-12 px-0 d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className='col-6 visit-num'>
                                        <p>{cardsData.length}</p>
                                        <p>الزيارات</p>
                                    </div>
                                    <div className='col-6 title'>
                                        <p>الزيارات السابقة</p>
                                    </div>
                                </div>
                            </div>
                            <div className={`col-12 px-0 pb-5 content ${expanded ? 'expanded' : ''}`}>
                                {currentCards.map((cardData, index) => (
                                    <CardComponent key={index} data={cardData} onUpdate={(updatedData) => handleCardUpdate(updatedData, index)} />
                                ))}
                            </div>
                        </div>

                        <button className='more' onClick={loadMoreCards}>
                            <img src={arrowRed} alt="arrow" className={expanded ? '' : ''} />
                            {expanded ? 'المزيد' : 'المزيد'}
                        </button>

                    </div>
                </div>
            </div>
        </>
    );
};

export default LatestVisit;
