import './DivBox.css'
const DivBox = (props) => {
    return (
        <>
            <div className={`box ${props.className || ''}`}>
                <div className="box-cont ">
                    {props.children}
                </div>
            </div>
        </>
    )
}

export default DivBox