import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { SignUp, SignIn, Rate, ResturantInfo, LatestVisit } from './Pages/index';
import ProtectedRoutes from './utils/protectedRoutes';
import { ErrorBoundary } from 'react-error-boundary';

// مكون للتعامل مع الأخطاء
const ErrorFallback = ({ error }) => {
  return (
    <div role="alert">
      <p>حدث خطأ:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('isAuthenticated') === 'true'
  );

  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  // Handler to log the user out
  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.setItem('isAuthenticated', 'false');
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Router>
        <Routes>
          {/* Logging the user out when they go to SignIn or SignUp */}
          <Route path="/" element={<SignIn setIsAuthenticated={setIsAuthenticated} onLogout={handleLogout} />} />
          <Route path="/SignIn" element={<SignIn setIsAuthenticated={setIsAuthenticated} onLogout={handleLogout} />} />
          <Route path="/SignUp" element={<SignUp setIsAuthenticated={setIsAuthenticated} onLogout={handleLogout} />} />
          {/* Protected routes */}
          <Route element={<ProtectedRoutes isAuthenticated={isAuthenticated} />}>
            <Route path="/Rate" element={<Rate />} />
            <Route path="/ResturantInfo" element={<ResturantInfo />} />
            <Route path="/LatestVisit" element={<LatestVisit />} />
          </Route>
        </Routes>
      </Router>
    </ErrorBoundary>
  
  );
}

export default App;
