import './SignUp.css';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import userIcon from '../../assets/img/user.svg';
import userInsta from '../../assets/img/insta.svg';
import userPhone from '../../assets/img/phone.svg';
import userDate from '../../assets/img/date.svg';

import logo from '../../assets/img/logo.png';
import { DivBox, Button, Input } from '../../components/index';
import { postData } from '../../Service/ServiceAPI';

const SignUp = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState('');
  const [userphone, setUserphone] = useState('');
  const [userinsta, setUserinsta] = useState('');
  // const [userBirthday, setUserBirthday] = useState('');
  const [errorName, seterrorName] = useState('');
  const [errorPhone, seterrorPhone] = useState('');
  const [errorBirthday, seterrorBirthday] = useState('');
  const [errorWrongePhone, seterrorWrongePhone] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isClicked, setIsClicked] = useState(false); // Track if input is clicked
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const handleInputFocus = () => setIsFocused(true);
  const handleInputBlur = () => setIsFocused(false);

  const handleInputClick = () => {
      setIsClicked(true); // Hide label after the first click
  };
  const handleDayChange = (e) => setDay(e.target.value);
  const handleMonthChange = (e) => setMonth(e.target.value);
  const handleYearChange = (e) => setYear(e.target.value);
  const userBirthday = `${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;


  const navigate = useNavigate();

  const postUserData = async () => {
    try {
      
      console.log('User Birthday:', userBirthday);
      const dataToSend = {
        name: username,
        phone_number: userphone,
        instagram_account: userinsta,
        birth_date: userBirthday,
      };
      const result = await postData('/customers/create', dataToSend);

      seterrorName('');
      seterrorPhone('');
      seterrorBirthday('');
      seterrorWrongePhone('');
      const token = result.data.token; // Assuming the token is returned in the response
      localStorage.setItem('authToken', token); // Store the token in local storage
      setIsAuthenticated(true);
      navigate('/Rate');
    } catch (error) {
      const response = error.response?.data;
      seterrorWrongePhone(response?.wrong_number || '');

      if (response?.errors) {
        seterrorName(response.errors.name_error || '');
        seterrorPhone(response.errors.phone_number_error || '');
        seterrorBirthday(response.errors.birth_date_error || '');
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postUserData();
  };

  return (
    <div className='SignUp-page' style={{ overflow: 'scroll' }}>
      <div className="container d-flex justify-content-center align-items-center min-vh-100">
        <img alt='' src={logo} className="pb-5 pt-1" />
        <form onSubmit={handleSubmit}>
          <DivBox>
            <p className="box-title-1">تسجيل </p>
            <p className="box-text">انشئ حسابك من خلال إدخال المعلومات التالية وأستمتع بتجربة مميزة :</p>

            <div className="box-input">
              <img alt='' src={userIcon} className="box-input-icon" />
              <Input
                type="text"
                className="box-input-context"
                placeholder="أسمك"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <div className='error' id="error">
                {errorName}
              </div>
            </div>
            <div className="box-input">
              <img alt='' src={userPhone} className="box-input-icon" />
              <Input
                type="text"
                className="box-input-context"
                placeholder="رقم هاتفك"
                value={userphone}
                onChange={(e) => setUserphone(e.target.value)}
              />
              <div className='error' id="error">
                {errorPhone}
              </div>
              <div className='error' id="error">
                {errorWrongePhone}
              </div>
            </div>

            <div className="box-input-center">
              <div className="box-input px-0">
                <img alt='' src={userInsta} className="box-input-icon left-style mr-5" />
                <Input
                  type="text"
                  className="box-input-context"
                  placeholder="حساب الأنستغرام"
                  value={userinsta}
                  onChange={(e) => setUserinsta(e.target.value)}
                />
              </div>
              <div className="box-input px-0 pt-sm-0 pt-1">
                {/* <img alt='' src={userDate} className="box-input-icon left-style date-input" />
                <input
                  className="box-input-context-2 date-input"
                  placeholder="تاريخ ميلادك"
                  type={isFocused || userBirthday ? "date" : "text"} value={userBirthday}
                  onFocus={(e) => {
                    setIsFocused(true);
                    e.target.type = "date";
                    e.target.style.textAlign = "start"; // Switch to date input on focus
                  }}
                  onBlur={(e) => {
                    setIsFocused(false);
                    if (!userBirthday) {
                      e.target.type = "text";
                      // Switch back to text if no value is selected
                    }
                  }}
                  onChange={(event) => setUserBirthday(event.target.value)}
                  style={{ paddingLeft: '10px', width: '100%', boxSizing: 'border-box', textAlign: 'start' }} // Adjust padding for icon
                /> */}
                <div className=' box-input-context-3' >
                  <div className=' w-100 d-flex justify-content-center img-placement ' >
                    <img alt='' src={userDate} className="date-img " />
                  </div>
                  {!isClicked && !isFocused && (
                    <label htmlFor="inputField" className="label-date">عيد ميلادك</label>
                  )}
                  <div className='col-3 d-flex justify-content-center algin-items-center'>
                    <input className='inputs p-0'
                      type="number"
                      value={day}
                      onChange={handleDayChange}
                      onClick={handleInputClick}
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      placeholder="يوم"
                      min="1"
                      max="31"
                      style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
                    />
                    <p className='m-0 pt-2' >/</p>
                  </div>
                  <div className='col-3 d-flex algin-items-center'>
                    <input className='inputs p-0'
                      type="number"
                      value={month}
                      onChange={handleMonthChange}
                      onClick={handleInputClick}
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      
                      placeholder="شهر"
                      min="1"
                      max="12"
                      style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
                    />

                  </div>
                  <div className='col-3 d-flex algin-items-center'>
                    <p className='m-0 pt-2 ' >/</p>
                    <input className='inputs p-0'
                      type="number"
                      value={year}
                      onChange={handleYearChange}
                      onClick={handleInputClick}
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      
                      id="inputField"
                      placeholder="سنة"
                      min="1900"
                      max="2100"
                      style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
                    />
                  </div>

                </div>
                <div className='error' id="error">
                  {errorBirthday}
                </div>
              </div>

            </div>
            <p className="box-link pb-5">تمتلك حساب بالفعل؟
              <Link to="/SignIn" className="box-link">
                سجل الدخول
              </Link>
            </p>
            <Button type="submit">تقدم</Button>
          </DivBox>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
