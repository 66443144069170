import './Button.css'

const Button = (props) => {
    return (
        <>
            <div className="box-button-border" >
                <button className={`box-button ${props.className || ''}`} >
                    {props.children}
                </button>
            </div>
        </>
    )
}

export default Button