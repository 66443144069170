import './ResturantInfo.css';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { useNavigate } from 'react-router-dom';

import logoRed from '../../assets/img/logoRed.png';
import CheckMark from '../../assets/img/CheckMark.png';
import loction from '../../assets/img/loction.svg';
import phoneRed from '../../assets/img/phoneRed.svg';
import instagramRed from '../../assets/img/instagramRed.svg';
import PDF from '../../assets/img/PDF.png';

import shadowRed from '../../assets/img/shadowRed.svg';


import resturant_1 from '../../assets/img/resturant_1.JPG';
import resturant_2 from '../../assets/img/resturant_2.JPG';
import resturant_3 from '../../assets/img/resturant_3.JPG';


const ResturantInfo = () => {
    const navigate = useNavigate();
    
    const handleButtonClick = () => {
        navigate('/LatestVisit'); 
    };
    return (
        <>
            <div className="container d-flex justify-content-center align-items-center pb-5">
                <img alt='' src={logoRed} className="pb-5 pt-1" />
                <div className='row w-100 justify-content-center align-items-center flex-column-reverse'>
                    <p className='title-respon '>.تم الأرسال , شكراً لتسجيلك </p>
                    <div className='check-mark-cont col-1'>
                        <img alt='' src={CheckMark} ></img>
                    </div>
                </div>

                <button className='old-visits'style={{zIndex:'10'}}  onClick={handleButtonClick}>الزيارات السابقة</button>
            </div>
            <div className='cont '>
                <div className='row w-100 h-100 m-0'>
                    <div className='col pdf-pos order-1 order-md-0'>
                        <a className='pdf-cont row align-items-center ' style={{textDecoration:'none'}} href='https://sparrowapi.ahmedalamry.com/public/menu/menu.pdf'  >
                            <div className='col pt-2 pb-2 '>
                                <p className='pdf-manu m-0'>المنيو</p>
                            </div>
                            <img src={PDF} alt="" className='col-3 pt-1 px-0' style={{ width: '60px' }} />
                        </a>
                        
                    </div>
                    <div className='col-md-4 col-12 cont-info p-5 order-0 order-md-1 '>
                        <p className='to-call'>للتواصل والأستفسار</p>
                        <div className=' w-100 h-100 m-3'>
                            <div className='row w-100'>
                                <div className='col d-flex gap-3 align-items-center' >
                                    <img src={loction} alt="" className='pb-3' />
                                    <p className='loction-text'>البصرة - حي عمان - عمارة كودو كودو طابق 2</p>
                                </div>
                            </div>
                            <div className='row w-100'>
                                <div className='col d-flex gap-3 align-items-center' >
                                    <img src={phoneRed} alt="" className='pb-3' />
                                    <p className='info-text'>07814444450</p>
                                </div>
                            </div>
                            <div className='row w-100'>
                                <div className='col d-flex gap-3 align-items-center' >
                                    <img src={instagramRed} alt="" className='pb-3' />
                                    <p className='info-text'>sparrow_basra</p>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className='col-12 order-2 img-cont'>
                        <img src={shadowRed} alt="" className='shadow-1' />
                        <img src={shadowRed} alt="" className='shadow-2' />
                        <img src={shadowRed} alt="" className='shadow-3' />
                        <img src={shadowRed} alt="" className='shadow-4' />
                        <div className='w-100 h-100 row justify-content-center gap-5'>
                            <div className='img-placment' >
                                <img src={resturant_1} alt="" className='img-show' />
                            </div>
                            <div className='img-placment'>
                                <img src={resturant_2} alt="" className='img-show' />
                            </div>
                            <div className='img-placment'>
                                <img src={resturant_3} alt="" className='img-show' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResturantInfo;
