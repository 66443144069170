import './Button2.css';

const Button2 = (props) => {
    return (
        <div className="box-button-border-2">
            <button 
                className={`box-button-2 ${props.className}`} 
                onClick={props.onClick}>
                {props.children}
            </button>
        </div>
    );
};

export default Button2;
