import './Input.css'

const Input = ({ type = 'text', placeholder = '', value, onChange }) => {
  return (
    <input type={type} 
    className="box-input-context" 
    placeholder={placeholder}
    value={value}
    onChange={onChange} />
  )
}
export default Input