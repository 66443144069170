import './Rate.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/img/logo.png';
import arrowImage from '../../assets/img/arrow.svg';
import addImg from '../../assets/img/add.svg';
import { DivBox, Button2, Input } from '../../components/index';
import { postData } from '../../Service/ServiceAPI';
import Delete from '../../assets/img/Delete.svg';

const Rate = () => {
    const [mealName, setMealName] = useState('');
    const [experience, setExperience] = useState('');
    const [ratings, setRatings] = useState([{ mealName: '', rating: '' }]);
    const [general_experience_error, setGeneral_experience_error] = useState('');
    const [most_liked_meal_error, setmost_liked_meal_error] = useState('');
    const [mealErrors, setMealErrors] = useState([]);

    const navigate = useNavigate();

    // Handle meal name and rating changes for each entry
    const handleMealChange = (index, field, value) => {
        const updatedRatings = [...ratings];
        updatedRatings[index] = {
            ...updatedRatings[index],
            [field]: value
        };
        setRatings(updatedRatings);
    };
    const handleDeleteRating = (index) => {
        setRatings(prevRatings => prevRatings.filter((_, i) => i !== index)); // Remove the item by index
    };

    // Function to add a new meal entry
    const addNewRating = () => {
        setRatings([...ratings, { mealName: '', rating: '' }]); // Add a new empty entry
    };

    const handleSubmit = async () => {
        const data = {
            most_liked_meal: mealName,
            general_experience: experience,
            meals: ratings.map(rating => ({
                meal_name: rating.mealName,
                rating: rating.rating
            }))
        };
        try {
            // Use postData to submit the form data
            const response = await postData('/feedback', data);
            console.log('Data sent successfully:', response);
            navigate('/ResturantInfo'); 
        } catch (error) {
            setGeneral_experience_error(error.response?.data.errors?.general_experience_error || '');
            setmost_liked_meal_error(error.response?.data.errors?.most_liked_meal_error || '');

            // Set errors for each meal
            const updatedMealErrors = ratings.map((_, index) => ({
                meal_name_error: error.response?.data.errors[`meal_name_error_${index}`] || '',
                meal_rating_error: error.response?.data.errors[`meal_rating_error_${index}`] || ''
            }));
            setMealErrors(updatedMealErrors);
        }
    };

    const handleReset = () => {
        // Reset all input values
        setMealName(''); // Clear meal name
        setExperience(''); // Clear experience
        setRatings([{ mealName: '', rating: '' }]); // Reset ratings to initial state with empty values
    };

    return (
        <div className='rate-page'>
            <div className="container d-flex justify-content-center align-items-center min-vh-100">
                <img alt='' src={logo} className="pb-5 pt-1" />
                <DivBox className="max-width-box" style={{ padding: '0px' }}>
                    <p className='box-title'>سجل تقييمك وتجربتك</p>
                    {/* Meal Name input */}
                    <div style={{ width: '100%' }}>
                        <Input
                            type='text'
                            placeholder='أكثر وجبة أعجبتك'
                            value={mealName}
                            onChange={(e) => setMealName(e.target.value)}
                        />
                        <div className='error' id="error">
                            {most_liked_meal_error}
                        </div>
                    </div>

                    <div className='box-title-cont '>
                        <p className='box-title-input'>: قيّم وجباتك</p>
                    </div>

                    {/* Render ratings array */}
                    {ratings.map((rating, index) => (
                        <div className="box-selecte-item-cont mb-1" key={index}>
                            <div className="box-selecte-item">
                                <img src={arrowImage} alt="arrow" className="select-arrow" />
                                <div style={{ width: '100%' }}>
                                    <select
                                        onChange={(e) => handleMealChange(index, 'rating', e.target.value)}
                                        value={rating.rating}
                                    >
                                        <option disabled value="" hidden>تقييمها</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                    <div className='error'>
                                        {mealErrors[index]?.meal_rating_error}
                                    </div>
                                </div>

                            </div>
                            <div style={{ width: '100%' }}>
                                <div className='input-context d-flex'>
                                    <input
                                        type="text"
                                        className='input-context-text '
                                        placeholder="أسم الوجبة"
                                        value={rating.mealName}
                                        onChange={(e) => handleMealChange(index, 'mealName', e.target.value)}
                                    />
                                    <button
                                        className='input-context-img'
                                        onClick={() => handleDeleteRating(index)}
                                    >
                                        <img src={Delete} alt="" />
                                    </button>

                                </div>

                                <div className='error'>
                                    {mealErrors[index]?.meal_name_error}
                                </div>
                            </div>

                        </div>
                    ))}
                    {/* Button to add a new rating */}
                    <button className="add-box-selecte-item-cont" onClick={addNewRating}>
                        <div className="box-selecte-item">
                            <img
                                src={arrowImage}
                                alt="arrow"
                                className="select-arrow"
                            />
                            <select disabled className='add-select'>
                                <option selected value="" hidden>تقييمها</option>
                            </select>
                        </div>
                        <div className='add-input row justify-content-end px-2'>
                            <div className='add-title col gap-2 '>
                                أضف وجبة
                                <img src={addImg} alt="" className='add-img-w' />

                            </div>

                        </div>
                    </button>
                    {/* Experience input */}
                    <div style={{ width: '100%' }}>
                        <textarea
                            className='text-area'
                            placeholder='تجربتك بشكل عام'
                            value={experience}
                            onChange={(e) => setExperience(e.target.value)}
                        />
                        <div className='error' id="error">
                            {general_experience_error}
                        </div>
                    </div>

                    <div className='row w-100 p-0 pt-5 m-0'>
                        <div className='col p-0'>
                            <Button2 className="box-button-colorWhite" onClick={handleReset}>مسح</Button2>
                        </div>
                        <div className='col p-0'>
                            <Button2 type="submit" onClick={handleSubmit}>أرسال</Button2>
                        </div>
                    </div>
                </DivBox>
            </div>
            <script src="/js/Rate.js"></script>
        </div>
    );
};

export default Rate;
